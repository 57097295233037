import React, { useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Typography, Box, Divider } from "@material-ui/core"

// Utils
// import { useStore } from "store"
// import getParameter from "utils/getParameter"
// import * as theme from "theme"

// Components
import BackgroundImage from "../../../components/backgroundImage"
import Edges from "../../../components/edges"
import ArrowDown from "../../../../static/images/icons/arrow-down-scroll.svg"
import Link from "../../../components/link"
import Textarea from "../../../components/textarea"
import { useStore } from "store"
import PlayIcon from "../../../../static/images/icons/play.svg"
// Assets
// import PlayIcon from "../../../../static/images/icons/play-icon.svg"
// import MastheadIcon from "../../../assets/svg/Masthead.svg"
// impoimport * as theme from "theme"
import * as theme from "theme"

export default function ForestryFactsTemplate(props) {
  const {
    data: {
      wpPage: {
        templateForestryFacts: {
          section1,
          section2,
          section3,
          section4,
          section5,
          section6,
          section7,
          section8,
          section9,
          section10,
          section11,
          videoUrl,
          videoThumbnail
          // section12
        }
      }
    }
  } = props

  const dispatch = useStore()[1]
  const showLightBox = () => {
    dispatch({
      type: "SET_LIGHTBOX",
      payload: {
        open: true,
        slide: 0,
        slides: [{ link: videoUrl, image: videoThumbnail }],
        options: {
          thumbs: false
        }
      }
    })
  }
  return (
    <>
      <Container>
        {/*section 1---------------------------------------------------------------------------------------- */}

        <Section1Container>
          <Edges size="lg">
            <Content>
              {section1.header1 && <Section1Heading1>{section1.header1}</Section1Heading1>}
              <VideoText>
                {section1.text1 && <Section1Text1> {section1.text1}</Section1Text1>}
                <VideoContainer>
                  {videoUrl && (
                    <Image onClick={() => showLightBox()}>
                      {videoUrl?.url.includes("youtu") && <PlayIcon />}
                      {videoThumbnail && <BackgroundImage position="absolute" image={videoThumbnail} maxWidth={400} />}
                    </Image>
                  )}
                </VideoContainer>
              </VideoText>
              <Section1Divider variant="middle" />
              <Section1Box>
                {section1.header2 && !section1.header2Image && (
                  <Section1Heading2TextOption>{section1.header2} </Section1Heading2TextOption>
                )}
                {section1.header2Image && !section1.header2 && (
                  <Section1Heading2ImageOption>
                    <GatsbyImage image={section1.header2Image.localFile.childImageSharp.gatsbyImageData} />
                  </Section1Heading2ImageOption>
                )}
                {section1.text2 && <Section1Text2>{section1.text2}</Section1Text2>}
              </Section1Box>
            </Content>
          </Edges>
          <SectionEnd>
            {section1.source && (
              <Section1Source>
                <Textarea content={section1.source} />
              </Section1Source>
            )}
            <ArrowContainer href="#section2">
              <ArrowDown />
            </ArrowContainer>
          </SectionEnd>
          {section1.backgroundImage && <BackgroundImage image={section1.backgroundImage} />}
        </Section1Container>

        {/*section 2---------------------------------------------------------------------------------------- */}

        <Section2Container id="section2">
          <Edges size="lg">
            <Content>
              {section2.headline && <Section2Heading1>{section2.headline}</Section2Heading1>}
              {section2.subHeadline && <Section2SubHeadline>{section2.subHeadline}</Section2SubHeadline>}
              {section2.subHeadline2 && <Section2SubHeadline2>{section2.subHeadline2}</Section2SubHeadline2>}
              <Section2ImageContainer>
                {section2.image && <GatsbyImage image={section2.image.localFile.childImageSharp.gatsbyImageData} />}
              </Section2ImageContainer>
              {section2.text && <Section2Text>{section2.text}</Section2Text>}
            </Content>
          </Edges>
          <SectionEnd>
            {section2.source && (
              <Section2Source>
                <Textarea content={section2.source} />
              </Section2Source>
            )}
            <ArrowContainer secondary href="#section3">
              <ArrowDown />
            </ArrowContainer>
          </SectionEnd>
        </Section2Container>

        {/*section 3---------------------------------------------------------------------------------------- */}
        <Section3OuterContainer id="section3">
          <Section3InnerContainer>
            <Section3ImageContainer>
              <Edges size="lg">
                {section3.image && <GatsbyImage image={section3.image.localFile.childImageSharp.gatsbyImageData} />}
              </Edges>
            </Section3ImageContainer>
            {section3.headline && <Section3Heading1>{section3.headline}</Section3Heading1>}

            <SectionEnd>
              {section3.source && (
                <Section3Source>
                  <Textarea content={section3.source} />
                </Section3Source>
              )}
              <ArrowContainer secondary href="#section4">
                <ArrowDown />
              </ArrowContainer>
            </SectionEnd>
          </Section3InnerContainer>
        </Section3OuterContainer>

        {/*section 4---------------------------------------------------------------------------------------- */}

        <Section2Container id="section4">
          <Edges size="lg">
            <Content>
              <Section4ImageContainer>
                {section4.image && <GatsbyImage image={section4.image.localFile.childImageSharp.gatsbyImageData} />}
              </Section4ImageContainer>
              {section4.text1 && <Section4Text1>{section4.text1}</Section4Text1>}
              {section4.text2 && <Section4Text2>{section4.text2}</Section4Text2>}
            </Content>
          </Edges>
          <SectionEnd>
            {section4.source && (
              <Section4Source>
                <Textarea content={section4.source} />
              </Section4Source>
            )}
            <ArrowContainer href="#section5">
              <ArrowDown />
            </ArrowContainer>
          </SectionEnd>
          {section4.backgroundImage && <BackgroundImage image={section4.backgroundImage} />}
        </Section2Container>

        {/*section 5---------------------------------------------------------------------------------------- */}
        <Section5OuterContainer id="section5">
          <Section5InnerContainer>
            <Section5ImageContainer>
              {section5.image1 && <GatsbyImage image={section5.image1.localFile.childImageSharp.gatsbyImageData} />}
              {section5.text1 && (
                <Section5Text>
                  <Textarea content={section5.text1} />
                </Section5Text>
              )}
            </Section5ImageContainer>
            <Section5ImageContainer>
              {section5.image2 && <GatsbyImage image={section5.image2.localFile.childImageSharp.gatsbyImageData} />}
              {section5.text2 && (
                <Section5Text>
                  <Textarea content={section5.text2} />
                </Section5Text>
              )}
            </Section5ImageContainer>
            <Section5TextContent>
              {section5.text3 && <Section5Text3>{section5.text3}</Section5Text3>}
            </Section5TextContent>
            <SectionEnd>
              {section5.source && (
                <Section5Source>
                  <Textarea content={section5.source} />
                </Section5Source>
              )}
              <ArrowContainer secondary href="#section6">
                <ArrowDown />
              </ArrowContainer>
            </SectionEnd>
          </Section5InnerContainer>
        </Section5OuterContainer>

        {/*section 6---------------------------------------------------------------------------------------- */}

        <Section2Container id="section6">
          <Edges size="lg">
            <Content>
              {section6.headline1 && <Section6Text1>{section6.headline1}</Section6Text1>}
              {section6.headline2 && <Section6Text2>{section6.headline2}</Section6Text2>}
              {section6.text && <Section6Text3>{section6.text}</Section6Text3>}
            </Content>
          </Edges>
          <SectionEnd>
            {section6.source && (
              <Section6Source>
                <Textarea content={section6.source} />
              </Section6Source>
            )}
            <ArrowContainer href="#section7">
              <ArrowDown />
            </ArrowContainer>
          </SectionEnd>
          {section6.backgroundImage && <BackgroundImage image={section6.backgroundImage} />}{" "}
          <Section6ImageContainer>
            {section6.backgroundImage2 && (
              <GatsbyImage image={section6.backgroundImage2.localFile.childImageSharp.gatsbyImageData} />
            )}
          </Section6ImageContainer>
        </Section2Container>

        {/*section 7---------------------------------------------------------------------------------------- */}

        <Section2Container id="section7">
          <Edges size="lg">
            <Content>
              {section7.headline1 && <Section7Text1>{section7.headline1}</Section7Text1>}
              {section7.text1 && <Section7Text2>{section7.text1}</Section7Text2>}
              {section7.headline2 && <Section7Text3>{section7.headline2}</Section7Text3>}
              {section7.text2 && <Section7Text2>{section7.text2}</Section7Text2>}
            </Content>
          </Edges>
          <SectionEnd>
            {section7.source && (
              <Section7Source>
                <Textarea content={section7.source} />
              </Section7Source>
            )}
            <ArrowContainer href="#section8">
              <ArrowDown />
            </ArrowContainer>
          </SectionEnd>
          {section7.backgroundImage && <BackgroundImage image={section7.backgroundImage} />}{" "}
          <Section7ImageContainer>
            {section7.image && <GatsbyImage image={section7.image.localFile.childImageSharp.gatsbyImageData} />}
          </Section7ImageContainer>
        </Section2Container>

        {/*section 8---------------------------------------------------------------------------------------- */}

        <Section8Container id="section8">
          <Edges size="lg">
            <Content>
              <Section2ImageContainer>
                {section8.image && <GatsbyImage image={section8.image.localFile.childImageSharp.gatsbyImageData} />}
              </Section2ImageContainer>
              {section8.text && <Section8Text>{section8.text}</Section8Text>}
            </Content>
          </Edges>
          <SectionEnd>
            {section8.source && (
              <Section8Source>
                <Textarea content={section8.source} />
              </Section8Source>
            )}
            <ArrowContainer secondary href="#section9">
              <ArrowDown />
            </ArrowContainer>
          </SectionEnd>
        </Section8Container>

        {/*section 9---------------------------------------------------------------------------------------- */}

        <Section8Container id="section9">
          <Edges size="lg">
            <Content>
              <Section8ImageContainer>
                {section9.image && <GatsbyImage image={section9.image.localFile.childImageSharp.gatsbyImageData} />}
              </Section8ImageContainer>
              {section9.text && <Section8Text>{section9.text}</Section8Text>}
            </Content>
          </Edges>
          <SectionEnd>
            <ArrowContainer secondary href="#section10">
              <ArrowDown />
            </ArrowContainer>
          </SectionEnd>
        </Section8Container>

        {/*section 10---------------------------------------------------------------------------------------- */}

        <Section10Container id="section10">
          <Edges size="lg">
            <Content>
              {section10.headline && <Section10Heading>{section10.headline}</Section10Heading>}
              {section10.text && <Section10Text>{section10.text}</Section10Text>}

              <Section10ImageContainer>
                {section10.image && <GatsbyImage image={section10.image.localFile.childImageSharp.gatsbyImageData} />}
              </Section10ImageContainer>
            </Content>
          </Edges>
          <SectionEnd>
            <ArrowContainer secondary href="#section11">
              <ArrowDown />
            </ArrowContainer>
          </SectionEnd>
          {section10.backgroundImage && <BackgroundImage image={section10.backgroundImage} />}
        </Section10Container>

        {/*section 11---------------------------------------------------------------------------------------- */}

        <Section11Container id="section11">
          <Edges size="lg">
            <Content>
              <div id="borderTop"></div>
              {section11.headline && <Section11Heading>{section11.headline}</Section11Heading>}
              {section11.link1 && (
                <Section11Link to={section11.link1.url}>
                  <LinkText>{section11.link1.title}</LinkText>
                </Section11Link>
              )}
              {section11.link2 && (
                <Section11Link to={section11.link2.url}>
                  <LinkText>{section11.link2.title}</LinkText>
                </Section11Link>
              )}
              {section11.link3 && (
                <Section11Link to={section11.link3.url}>
                  <LinkText>{section11.link3.title}</LinkText>
                </Section11Link>
              )}
              {section11.link4 && (
                <Section11Link to={section11.link4.url}>
                  <LinkText>{section11.link4.title}</LinkText>
                </Section11Link>
              )}
            </Content>
          </Edges>
          <Section11ImageContainer>
            {section11.image && <GatsbyImage image={section11.image.localFile.childImageSharp.gatsbyImageData} />}
          </Section11ImageContainer>
        </Section11Container>
      </Container>
    </>
  )
}

const Container = styled.div``

// section 1
const Image = styled.div`
  flex-shrink: 0;
  position: relative;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${theme.colors.lightgrey};
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px 0px;

  @media (max-width: 460px) {
    max-width: 250px;
    height: 220px;
    img {
      max-width: 250px;
    }
  }

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    width: 400px;
    margin-left: 20px;
  }

  svg {
    height: 55px;
    filter: drop-shadow(2px 4px 6px black);
    opacity: 0.95;
    position: relative;
    z-index: 1;
    width: 55px;
  }
`
const VideoText = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 1130px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
  }
`
const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 400px;
  .gatsby-image-wrapper {
    width: 400px !important;
  }
  @media (max-width: 1000px) {
    width: 400px;
    padding-top: 10px;
    .gatsby-image-wrapper {
      max-width: 400px;
    }
  }
  @media (max-width: 460px) {
    max-width: 250px;
    img {
      max-width: 250px;
    }
  }
`
const Section1Text1 = styled(Typography)`
  color: black;
  font-size: 24px;
  line-height: 40px;
  font-weight: 500 !important; //dont have font-weight 500
  text-align: center;
  max-width: 675px;
  @media (max-width: 700px) {
    padding-top: 20px;
    font-size: 20px;
    line-height: 30px;
  }
  @media (max-width: 1000px) {
    wdith: 100%;
    max-width: 100%;
  }
`
const Section1Container = styled.div`
  height: 900px;
  position: relative;
  width: 100%;
  .gatsby-image-wrapper {
    height: 840px;
    width: 100%;
  }
  @media (max-width: 400px) {
    height: 920px;
  }
`
const Content = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  color: black;
  z-index: 2;
`
const Section1Heading1 = styled(Typography)`
  padding-top: 80px;
  font-size: 70px;
  font-weight: 700;
  line-height: 90px;
  color: black;
  text-align: center;
  @media (max-width: 700px) {
    padding-top: 0px;
    font-size: 50px;
    line-height: 70px;
  }
`
const Section1Box = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`
const Section1Heading2TextOption = styled(Typography)`
  width: 70%;
  padding-top: 100px;
  font-size: 200px;
  font-weight: 400;
  line-height: 200px;
  color: white;
  text-align: center;
  @media (max-width: 700px) {
    padding-top: 50px;
    font-size: 100px;
    line-height: 100px;
  }
`
const Section1Heading2ImageOption = styled.div`
  max-height: 150px;
  max-width: 100%;
  padding-right: 56px;
  img,
  .gatsby-image-wrapper {
    max-height: 150px;
    object-fit: contain !important;
  }

  @media (max-width: 1000px) {
    max-height: 70px;
    max-width: 30%;
    padding-right: 0;
    .gatsby-image-wrapper {
      max-height: 70px;
      object-fit: contain !important;
    }
  }
  @media (max-width: 768px) {
    padding-right: 0;
  }
`
const Section1Text2 = styled(Typography)`
  width: 30%;
  padding-top: 20px;
  font-size: 24px;
  color: black;
  line-height: 40px;
  font-weight: 500 !important; //dont have font-weight 500
  text-align: left;
  max-width: 675px;
  @media (max-width: 1000px) {
    width: 90%;
    padding-top: 10px;
    font-size: 20px;
    line-height: 30px;
  }
`
const Section1Divider = styled(Divider)`
  width: 316px;
  background-color: black;
  margin: 70px 0 60px 0;
  @media (max-width: 1000px) {
    margin: 20px 0 10px 0;
  }
`
const Section1Source = styled.div`
  max-width: 100%;
  text-align: center;
  p,
  a {
    color: white !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 500 !important;
    font-style: normal !important;
    padding: 20px;
    text-decoration: none !important;
  }
`

const SectionEnd = styled.div`
  position: absolute;
  bottom: 20px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

const ArrowContainer = styled.a`
  width: 100%;
  display: flex;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
  @media (max-width: 700px) {
    bottom: 40px;
  }
  svg {
    path {
      fill: ${(props) => (props.secondary ? "black" : "white")};
    }
  }
`

// section 2
const Section2Container = styled.div`
  height: 840px;
  position: relative;
  width: 100%;
  background-color: #e5e5e5;
`
const Section2Heading1 = styled(Typography)`
  padding-top: 60px;
  font-size: 70px;
  font-weight: 700;
  line-height: 90px;
  color: black;
  text-align: center;
  @media (max-width: 700px) {
    font-size: 50px;
    line-height: 70px;
  }
  @media (max-width: 330px) {
    font-size: 40px;
    line-height: 60px;
  }
`
const Section2ImageContainer = styled.div`
  padding-top: 40px;
  img {
    max-height: 300px;
  }
  @media (max-width: 700px) {
    padding-top: 20px;
  }
`
const Section2SubHeadline = styled(Typography)`
  padding-top: 20px;
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  color: black;
  text-align: center;
  max-width: 675px;
  @media (max-width: 700px) {
    font-size: 32px;
    line-height: 40px;
  }
`
const Section2SubHeadline2 = styled(Typography)`
  padding-top: 5px;
  font-size: 24px;
  font-weight: 700;
  color: black;
  text-align: center;
  max-width: 675px;
  @media (max-width: 700px) {
    padding-top: 20px;
    font-size: 20px;
  }
`
const Section2Text = styled(Typography)`
  padding-top: 40px;
  font-size: 24px;
  color: black;
  line-height: 40px;
  font-weight: 500 !important; //dont have font-weight 500
  text-align: center;
  max-width: 675px;
  @media (max-width: 700px) {
    font-size: 20px;
    line-height: 30px;
  }
`
const Section2Source = styled.div`
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  p,
  a {
    color: #7d7d7d !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 500 !important;
    font-style: normal !important;
    padding: 20px;
    text-decoration: none !important;
  }
`
// section 3
const Section3OuterContainer = styled.div`
  height: 880px;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    height: 1020px;
  }
`

const Section3InnerContainer = styled.div`
  height: 880px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  z-index: 2;
  max-width: 2000px;
  margin: 0 auto;

  @media (max-width: 900px) {
    flex-direction: column;
    height: 1020px;
  }
`
const Section3ImageContainer = styled.div`
  width: 60%;
  .gatsby-image-container {
    width: 100%;
    height: 100%;
    max-height: 840px;
  }
  @media (max-width: 1250px) {
    width: 50%;
  }
  @media (max-width: 900px) {
    width: 100%;
    padding-top: 20px;
  }
`
const Section3Heading1 = styled(Typography)`
  width: 30%;
  padding-top: 60px;
  font-size: 60px;
  font-weight: 700;
  line-height: 80px;
  color: #4c663b;
  text-align: left;
  padding-right: 65px;

  @media (max-width: 1250px) {
    width: 50%;
    padding-right: 50px;
  }
  @media (max-width: 900px) {
    width: 100%;
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 40px;
    line-height: 60px;
  }
`
const Section3Source = styled.div`
  width: 100%;
  text-align: center;
  p,
  a {
    color: #7d7d7d !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 500 !important;
    font-style: normal !important;
    padding: 20px;
    text-decoration: none !important;
  }
`
// section 4
const Section4ImageContainer = styled.div`
  padding-top: 100px;
  @media (max-width: 700px) {
    padding-top: 50px;
  }
`
const Section4Text1 = styled(Typography)`
  padding-top: 190px;
  font-size: 36px;
  color: white;
  line-height: 48px;
  font-weight: 400;
  text-align: center;
  max-width: 675px;
  @media (max-width: 700px) {
    font-size: 32px;
    line-height: 40px;
  }
`
const Section4Text2 = styled(Typography)`
  padding-top: 20px;
  font-size: 24px;
  color: white;
  line-height: 40px;
  font-weight: 700 !important; //dont have font-weight 500
  text-align: center;
  max-width: 675px;
  @media (max-width: 700px) {
    padding-top: 10px;
    font-size: 20px;
    line-height: 30px;
  }
`
const Section4Source = styled.div`
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  p,
  a {
    color: #000 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 500 !important;
    font-style: normal !important;
    padding: 20px;
    text-decoration: none !important;
  }
`
// section 5

const Section5OuterContainer = styled.div`
  height: 800px;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  @media (max-width: 1120px) {
    height: 900px;
  }
`

const Section5InnerContainer = styled.div`
  height: 800px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  z-index: 2;
  flex-wrap: wrap;
  max-width: 2000px;
  margin: 0 auto;
  @media (max-width: 1120px) {
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    margin-top: 50px;
    flex-wrap: nowrap;
  }
`
const Section5ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;

  .gatsby-image-wrapper {
    width: 100%;
    max-width: 535px;
    max-height: 240px;
  }

  @media (max-width: 1120px) {
    width: 80%;
  }
`
const Section5Text = styled.div`
  padding-top: 20px;
  h4 {
    font-style: normal;
    font-weight: 900;
    font-size: 36px !important;
    line-height: 48px;
    text-align: center;
    color: #000000;
    max-width: 560px;
    @media (max-width: 1120px) {
      font-size: 32px !important;
      line-height: 40px;
      max-width: 100%;
    }
  }
`
const Section5Text3 = styled(Typography)`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 24px;
  color: black;
  line-height: 40px;
  font-weight: 500 !important; //dont have font-weight 500
  text-align: center;
  max-width: 675px;
  @media (max-width: 1120px) {
    font-size: 20px;
    line-height: 30px;
    width: 80%;
  }
`
const Section5TextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Section5Source = styled.div`
  text-align: center;
  p,
  a {
    color: #7d7d7d !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 500 !important;
    font-style: normal !important;
    padding: 20px;
    text-decoration: none !important;
  }
`
// section 6

const Section6ImageContainer = styled.div`
  position: absolute;
  margin-left: 100px;
  margin-right: 100px;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  height: 100%;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 700px) {
    margin-left: auto;
    margin-right: auto;
  }
`
const Section6Text1 = styled(Typography)`
  padding-top: 160px;
  font-size: 120px;
  color: white;
  line-height: 130px;
  font-weight: 400;
  text-align: center;
  letter-spacing: -0.02em;
  @media (max-width: 820px) {
    padding-top: 100px;
    font-size: 50px;
    line-height: 110px;
  }
`
const Section6Text2 = styled(Typography)`
  padding-top: 20px;
  font-size: 70px;
  color: white;
  line-height: 90px;
  font-weight: 700;
  text-align: center;
  max-width: 675px;
  @media (max-width: 700px) {
    font-size: 50px;
    line-height: 70px;
  }
`
const Section6Text3 = styled(Typography)`
  padding-top: 75px;
  font-size: 36px;
  color: white;
  line-height: 48px;
  font-weight: 400;
  text-align: center;
  max-width: 675px;
  @media (max-width: 700px) {
    padding-top: 40px;
    font-size: 30px;
    line-height: 40px;
  }
`
const Section6Source = styled.div`
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  p,
  a {
    color: #fff !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 500 !important;
    font-style: normal !important;
    padding: 20px;
    text-decoration: none !important;
  }
`
// section 7

const Section7ImageContainer = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 90px;
  height: 177px;
  width: 190px;
  .gatsby-image-wrapper {
    height: 177px;
    width: 190px;
  }
  @media (max-width: 1140px) {
    height: 120px;
    width: 120px;
    bottom: 95px;
    .gatsby-image-wrapper {
      height: 100px;
      width: 120px;
    }
  }
`
const Section7Text1 = styled(Typography)`
  padding-top: 80px;
  font-size: 120px;
  color: white;
  line-height: 130px;
  font-weight: normal;
  text-align: center;
  letter-spacing: -0.02em;
  @media (max-width: 1140px) {
    padding-top: 40px;
    font-size: 50px;
    line-height: 60px;
  }
`
const Section7Text2 = styled(Typography)`
  padding-top: 10px;
  font-size: 36px;
  color: white;
  line-height: 48px;
  font-weight: 400;
  text-align: center;
  max-width: 675px;
  @media (max-width: 1140px) {
    font-size: 30px;
    line-height: 40px;
  }
`
const Section7Text3 = styled(Typography)`
  padding-top: 20px;
  font-size: 120px;
  color: white;
  line-height: 130px;
  font-weight: 400;
  text-align: center;
  letter-spacing: -0.02em;
  @media (max-width: 1140px) {
    padding-top: 40px;
    font-size: 50px;
    line-height: 60px;
  }
`
const Section7Source = styled.div`
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  p,
  a {
    color: #fff !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 500 !important;
    font-style: normal !important;
    padding: 20px;
    text-decoration: none !important;
  }
`
// section 8 & 9

const Section8Container = styled.div`
  height: 800px;
  position: relative;
  width: 100%;
  background: linear-gradient(180deg, #ffffff 94.17%, #f7f7f7 100%);
`
const Section8Text = styled(Typography)`
  color: #444444;
  font-size: 36px;
  line-height: 48px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  max-width: 820px;
  letter-spacing: -0.01em;
  @media (max-width: 700px) {
    font-size: 32px;
    line-height: 42px;
  }
`

const Section8ImageContainer = styled.div`
  padding-top: 105px;
  @media (max-width: 700px) {
    padding-top: 50px;
  }
`
const Section8Source = styled.div`
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  p,
  a {
    color: #7d7d7d !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 500 !important;
    font-style: normal !important;
    padding: 20px;
    text-decoration: none !important;
  }
`
// section 10
const Section10Container = styled.div`
  height: 1680px;
  position: relative;
  width: 100%;
  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 8.54%, #ffffff 100%);
  }
  @media (max-width: 700px) {
    height: 1100px;
  }
`
const Section10Heading = styled(Typography)`
  padding-top: 80px;
  font-size: 70px;
  font-weight: 700;
  line-height: 90px;
  color: black;
  text-align: center;
  max-width: 555px;
  @media (max-width: 700px) {
    font-size: 40px;
    line-height: 50px;
  }
  @media (max-width: 330px) {
    font-size: 30px;
    line-height: 40px;
  }
`
const Section10Text = styled(Typography)`
  padding-top: 40px;
  font-size: 36px;
  line-height: 48px;
  color: black;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  max-width: 675px;
  letter-spacing: -0.01em;
  @media (max-width: 700px) {
    padding-top: 20px;
    font-size: 32px;
    line-height: 42px;
  }
`
const Section10ImageContainer = styled.div`
  padding-top: 115px;
  @media (max-width: 700px) {
    padding-top: 40px;
  }
`
// section 11
const Section11Container = styled.div`
  #borderTop {
    border-top: 3px solid #000000;
    position: absolute;
    right: 25%;
    left: 25%;
    top: 0;
  }
  height: 1210px;
  position: relative;
  width: 100%;
  background-color: white;
  @media (max-width: 700px) {
    height: 840px;
  }
`
const Section11Heading = styled(Typography)`
  padding-top: 40px;
  font-size: 36px;
  line-height: 48px;
  color: black;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  max-width: 820px;
  letter-spacing: -0.01em;
  @media (max-width: 700px) {
    padding-top: 40px;
    font-size: 32px;
    line-height: 42px;
  }
`
const Section11ImageContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 700px) {
    margin-left: auto;
    margin-right: auto;
  }
`

const Section11Link = styled(Link)`
  padding-top: 40px;
`
const LinkText = styled(Typography)`
  font-size: 24px;
  color: #4c663b;
  line-height: 40px;
  max-width: 820px;
  font-weight: 500 !important; //dont have font-weight 500
  text-align: center;
  &:hover {
    color: black;
  }
  @media (max-width: 700px) {
    max-width: 400px;
    font-size: 20px;
    line-height: 30px;
  }
`
export const CollectionQuery = graphql`
  query ForestryFactsTemplate($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      acfModules {
        hideSearch
      }
      templateForestryFacts {
        fieldGroupName
        videoUrl {
          target
          title
          url
        }
        videoThumbnail {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
        }
        section1 {
          fieldGroupName
          header1
          header2
          header2Image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
              }
            }
          }
          text1
          text2
          source
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        section2 {
          fieldGroupName
          headline
          subHeadline
          subHeadline2
          text
          source
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 950, quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
        section3 {
          fieldGroupName
          headline
          source
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 920, quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
        section4 {
          fieldGroupName
          text1
          text2
          source
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 980, quality: 100, layout: CONSTRAINED)
              }
            }
          }
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        section5 {
          fieldGroupName
          text3
          text2
          text1
          source
          image2 {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 535, quality: 100, layout: CONSTRAINED)
              }
            }
          }
          image1 {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 535, quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
        section6 {
          fieldGroupName
          headline1
          headline2
          text
          source
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          backgroundImage2 {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1190, quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
        section7 {
          fieldGroupName
          headline1
          headline2
          text1
          text2
          source
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 190, quality: 100, layout: CONSTRAINED)
              }
            }
          }
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        section8 {
          fieldGroupName
          text
          source
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 580, quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
        section9 {
          fieldGroupName
          text
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 580, quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
        section10 {
          fieldGroupName
          headline
          text
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 950, quality: 100, layout: CONSTRAINED)
              }
            }
          }
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        section11 {
          fieldGroupName
          headline
          link3 {
            target
            title
            url
          }
          link4 {
            target
            title
            url
          }
          link2 {
            target
            title
            url
          }
          link1 {
            target
            title
            url
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1440, quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`
